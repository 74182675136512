`
<template>
  <Loading v-if="isLoading" />
  <div v-else class="edit-coupon">
    <b-card>
      <div class="p-2 mb-1">
        <h1 class="m-0 page-title">{{ $t("coupons.coupon_infromation") }}</h1>
      </div>
      <hr />
      <validation-observer ref="EditCouponValidation">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group :label="$t('coupons.name')" label-for="Name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    type="text"
                    v-model="form_data.name"
                    placeholder=""
                    class="custom-input"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('coupons.code')" label-for="Code">
                <validation-provider
                  #default="{ errors }"
                  name="Code"
                  rules="required"
                >
                  <b-form-input
                    type="text"
                    v-model="form_data.code"
                    placeholder=""
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('coupons.discount_type')">
                <validation-provider
                  #default="{ errors }"
                  name="Discount type"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.type"
                    :options="discount_type"
                    :state="errors.length > 0 ? false : null"
                  >
                  </b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Value"
                rules="required"
                v-if="form_data.type === 'fixed'"
              >
                <b-form-group :label="$t('coupons.value')" label-for="value">
                  <b-input-group append="SAR">
                    <b-form-input
                      type="number"
                      v-model="form_data.value"
                      placeholder=""
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Value"
                rules="required"
                v-else
              >
                <b-form-group :label="$t('coupons.value')" label-for="value">
                  <b-input-group append="%">
                    <b-form-input
                      type="number"
                      v-model="form_data.value"
                      placeholder=""
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <b-form-group
                :label="$t('coupons.usage_limit')"
                label-for="usage limit"
              >
                <validation-provider
                  #default="{ errors }"
                  name="usage limit"
                  rules="required"
                >
                  <b-form-input
                    type="number"
                    v-model="form_data.usage_limit"
                    :state="errors.length > 0 ? false : null"
                    placeholder=""
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('coupons.start_date')">
                <validation-provider
                  #default="{ errors }"
                  name="start date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.start_date"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('coupons.end_date')">
                <validation-provider
                  #default="{ errors }"
                  name="end date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.end_date"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-checkbox
            switch
            v-model="form_data.is_active"
            :value="1"
            :unchecked-value="0"
          >
            {{ $t("coupons.enable_the_coupon") }}
          </b-form-checkbox>
          <b-button
            type="submit"
            variant="primary"
            block
            @click="EditCouponForm"
            class="primary-btn my-3"
            :disabled="isPending"
          >
            <span>{{ $t("coupons.save_changes") }}</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormSelect,
  BRow,
  BCol,
  BFormDatepicker,
  BFormCheckbox,
  BInputGroup,
  BCard,
} from "bootstrap-vue";
import Loading from "@/components/shared/loading/loading.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@core/utils/validations/validations";

export default {
  name: "EditCoupon",
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    BFormDatepicker,
    BFormCheckbox,
    BInputGroup,
    ValidationObserver,
    ValidationProvider,
    Loading,
    BCard,
  },
  data() {
    return {
      isLoading: true,
      isPending: false,
      current_id: null,
      form_data: {
        name: null,
        code: null,
        value: null,
        usage_limit: null,
        type: null,
        is_active: true,
        start_date: null,
        end_date: null,
      },
      archive: {},
      discount_type: [
        {
          value: null,
          text: this.$t("coupons.please_select_the_discount_type"),
          disabled: true,
        },
        {
          value: "percentage",
          text: this.$t("coupons.percentage"),
        },
        {
          value: "fixed",
          text: this.$t("coupons.fixed"),
        },
      ],
    };
  },

  created() {
    this.current_id = this.$route.params.id;
    this.getCouponData();
  },
  methods: {
    getCouponData() {
      const allValue = [
        "name",
        "code",
        "value",
        "usage_limit",
        "type",
        "start_date",
        "end_date",
        "is_active",
      ];
      this.$http
        .get(`admin/coupons/${this.current_id}`)
        .then((res) => {
          if (res.status === 200) {
            for (const key in allValue) {
              if (allValue[key] === "value") {
                if (res.data.data[allValue[key]].includes("SAR")) {
                  this.form_data[allValue[key]] = +res.data.data[
                    allValue[key]
                  ].replace("SAR", "");
                  this.archive[allValue[key]] = +res.data.data[
                    allValue[key]
                  ].replace("SAR", "");
                } else {
                  this.form_data[allValue[key]] = +res.data.data[
                    allValue[key]
                  ].replace("%", "");
                }
              } else if (allValue[key] === "is_active") {
                this.form_data[allValue[key]] = Number(
                  res.data.data[allValue[key]]
                );
                this.archive[allValue[key]] = Number(
                  res.data.data[allValue[key]]
                );
              } else {
                this.form_data[allValue[key]] = res.data.data[allValue[key]];
                this.archive[allValue[key]] = res.data.data[allValue[key]];
              }
            }
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    EditCouponForm() {
      this.$refs.EditCouponValidation.validate().then((success) => {
        if (success) {
          this.isPending = true;
          const formData = new FormData();
          for (const key in this.form_data) {
            if (this.form_data[key] !== this.archive[key])
              formData.append(key, this.form_data[key]);
          }

          this.$http
            .post(`admin/coupons/${this.current_id}?_method=put`, formData)
            .then((res) => {
              if (res.status === 200) {
                this.$helpers.makeToast(
                  "success",
                  res.data.message,
                  res.data.message
                );
                setTimeout(() => {
                  this.$router.push({ name: "couponsList" });
                }, 1000);
                this.isPending = false;
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);
              this.isPending = false;
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
`
